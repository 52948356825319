<template>
  <div class="reserve">
    <!--
    <form action="#" @submit="handleInput">
      <div class="input-control">
        <select v-model="selectedUser">
          <option value="0" disabled selected>Select your option</option>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }} {{ user.surname }}
          </option>
        </select>
        <button type="submit">Go</button>
      </div>
    </form>
  -->

    <div class="recipient-selector">
      <div class="recipients-wrapper" ref="recipientsWrapper">
        <ul class="recipients">
          <li
            v-for="user in users"
            :key="user.id"
            @click="selectUser(user.id)"
            :class="{ active: selectedUser === user.id }"
          >
            <div class="recipient">
              <div class="recipient-name">
                {{ user.name }} {{ user.surname }}
              </div>
              <div class="recipient-email">
                {{ user.email }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="buttons">
        <button class="button" @click="goUp()">
          <font-awesome-icon icon="fa-regular fa-circle-up"></font-awesome-icon>
        </button>
        <button class="button" @click="goDown()">
          <font-awesome-icon
            icon="fa-regular fa-circle-down"
          ></font-awesome-icon>
        </button>
      </div>
    </div>

    <div class="form-error" v-if="error.length > 0">
      <span>{{ error }}</span>
    </div>
    <form action="#" @submit="handleInput">
      <div class="input-control">
        <input
          autocomplete="off"
          placeholder="Click here if you have a barcode"
          type="text"
          name="barcode"
          v-model="barcode"
          ref="barcodeInput"
          readonly="readonly"
          @input="onInputChange"
          @click="scrollToKeyboard"
        />
        <button type="submit">Go</button>
      </div>
    </form>

    <SimpleKeyboard
      v-show="true || openKeyboard"
      @onChange="onChange"
      :input="barcode"
    />

    <div class="modal-backdrop" v-if="modalShow">
      <div class="modal">
        {{ modalText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reserve {
  width: 85%;
}
.reserve .simple-keyboard {
  transform: scaleY(1.4) translateY(10%);
}

h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;
}

.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input,
select {
  width: calc(90% - 20px);
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
  margin-right: 20px;
}

input:focus,
select:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
  margin-top: 30px;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background-color: #333;
  color: #fff;
  border: 1px solid #222;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 32px;
}

.recipient-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46vh;
}

.recipients-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  scrollbar-width: none;
  border: 1px solid #333;
  border-radius: 10px;
  scroll-behavior: smooth;
}

.recipients-wrapper::-webkit-scrollbar {
  display: none;
}

.recipients {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  list-style: none;
}

.recipients li {
  display: flex;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #333;
  cursor: pointer;
}

.recipients li:last-of-type {
  border-bottom: none;
}

.recipient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.recipient-name {
  font-size: 32px;
  font-weight: bold;
}

.recipients li.active {
  background-color: #222;
  color: #fff;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  height: 100%;
  margin-left: 20px;
}

.button {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 0;
  margin: 10px;

  svg {
    font-size: 96px;
  }
}
</style>

<script>
import service from "@/api/service";
import lockerConfig from "../lockerConfig";
import SimpleKeyboard from "@/components/HorizontalKeyboard";

export default {
  name: "Leave A Package",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      barcode: "",
      error: "",
      modalShow: false,
      modalText: "",

      selectedUser: 0,
      users: [],

      openKeyboard: false,
      polling: null,
    };
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  mounted() {
    this.polling = setInterval(() => {
      this.focusInput();

      if (this.modalShow) {
        this.$refs.barcodeInput.disabled = true;
      } else {
        this.$refs.barcodeInput.disabled = false;
      }
    }, 0);

    service.allUsers().then((response) => {
      let gotUsers = response;

      // Sort names alphabetically
      gotUsers.sort((a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.users = [];
      gotUsers.forEach((user) => {
        if (user.is_public == 0) return;

        this.users.push({
          id: user.id,
          name: user.name,
          surname: user.surname,
        });
      });

      console.log(response, this.users);
    });
  },
  methods: {
    selectUser(user) {
      if (this.openKeyboard) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setTimeout(() => {
          this.openKeyboard = false;
          this.selectedUser = user;
        }, 50);

        console.log("select user", user);

        return;
      }

      this.selectedUser = user;
    },
    focusInput: function () {
      if (!this.openKeyboard) {
        // Remove focus from input if it has it
        if (document.activeElement === this.$refs.barcodeInput) {
          this.$refs.barcodeInput.blur();
        }

        return;
      }

      // Remove active class from all li elements
      let liElements = document.querySelectorAll(".recipients li");
      liElements.forEach((li) => {
        li.classList.remove("active");
      });

      this.$refs.barcodeInput.focus();
    },
    scrollToKeyboard: function () {
      this.$refs.barcodeInput.focus();

      this.openKeyboard = true;
      // // Scroll page to bottom.
      // setTimeout(() => {
      //   window.scrollTo({
      //     top: document.body.scrollHeight,
      //     behavior: "smooth",
      //   });
      // }, 100);
    },
    onChange(input) {
      this.barcode = input;
      if (!this.openKeyboard) this.scrollToKeyboard();
    },
    onInputChange(input) {
      this.barcode = input.target.value;
      if (!this.openKeyboard) this.scrollToKeyboard();
    },
    async handleInput(e) {
      e.preventDefault();
      if (this.openKeyboard) {
        // Handle code input

        this.error = "";
        let barcode = this.barcode.trim();
        this.barcode = "";
        barcode = barcode.toUpperCase();

        if (barcode.length <= 0) {
          this.error = "Codice mancante!";
          setTimeout(() => {
            this.error = "";
          }, 3000);
          return;
        }

        const barcodePrefix = barcode.substr(0, 3);
        let acceptedBarcodePrefixes = [];
        acceptedBarcodePrefixes = ["001", "002", "003"];

        if (!acceptedBarcodePrefixes.includes(barcodePrefix)) {
          this.error = "Attenzione: codice errato";
          setTimeout(() => {
            this.error = "";
          }, 3000);
          return;
        }

        this.modalText = "Checking the code...";
        this.modalShow = true;

        const isCodeCorrect = await service.checkCode(barcode);
        if (!isCodeCorrect) {
          this.error = "This barcode cannot be found in the database.";
          setTimeout(() => {
            this.error = "";
          }, 3000);
          this.modalShow = false;
          return;
        }

        if (isCodeCorrect.user_deposit_code) {
          this.$router.push({
            name: "Leave/SelectSize",
            query: {
              barcode: barcode,
              action: "userLeave",
            },
          });
          return;
        }

        this.$router.push({
          name: "Leave/SelectSize",
          query: {
            barcode: barcode,
            action: "leave",
          },
        });
        return;
      }

      if (this.selectedUser === 0) {
        this.error = "Please select a user";
        return;
      }

      let users = this.users.slice();

      let foundUser = 0;
      for (let i = 0; i < users.length; i++) {
        if (users[i].id == this.selectedUser) {
          foundUser = users[i];
          break;
        }
      }

      if (foundUser == 0) {
        this.error = "User not found";
        return;
      }

      this.$router.push({
        name: "QuickDeposit/SelectSize",
        query: {
          userId: this.selectedUser,
        },
      });
    },

    goUp() {
      // Scroll up
      let recipients = this.$refs.recipientsWrapper;
      recipients.scrollTop -= 80;
    },

    goDown() {
      // Scroll down
      let recipients = this.$refs.recipientsWrapper;
      recipients.scrollTop += 80;
    },
  },
};
</script>
